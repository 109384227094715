<template>
	<v-layout column fill-height ma-0 pa-0>
		<v-flex v-t="'sa.import.name'" class="display-2" py-2 shrink text-xs-center />
		<v-flex scroll-y>
			<v-layout align-center column fill-height justify-center>
				<v-flex my-3 shrink>
					<v-layout column>
						<v-flex v-t="'sa.import.select_accounting_firm'" class="title" mb-3 text-xs-center />
						<v-autocomplete
							v-model="selectedAccountingFirmId"
							color="primary"
							:items="accountingFirms"
							:label="$t('accounting_firm')"
							:loading="loadingAccountingFirmsList"
						/>
					</v-layout>
				</v-flex>
				<v-flex my-3 shrink>
					<v-layout column>
						<v-flex v-t="'sa.import.settings'" class="title" mb-3 text-xs-center />
						<v-checkbox v-model="doImportCustomers" color="primary" hide-details :label="$t('sa.customer_import.do_import_customers')" />
						<v-checkbox v-model="doImportUsers" color="primary" hide-details :label="$t('sa.customer_import.do_import_users')" />
					</v-layout>
				</v-flex>
				<v-flex my-3 shrink>
					<v-layout column>
						<v-flex v-t="'sa.import.drop_excel_file'" class="title" mb-3 text-xs-center />
						<v-flex mb-3>
							<DropZone v-model="excelFile" :accepted-mime-types="acceptedImportFileType" display-list @input="onInput" />
						</v-flex>
						<v-flex>
							<FileList v-if="excelFile" v-model="excelFileList" :deletable="false" />
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex my-3 shrink>
					<WButton :disabled="!excelFile || !selectedAccountingFirmId" @click="importData">{{ $t('actions.validate') }}</WButton>
				</v-flex>
			</v-layout>
		</v-flex>
		<ErrorsList v-model="showErrors" :errors="errorsReport" />
		<v-dialog v-model="loading" hide-overlay persistent width="300">
			<v-card color="primary" dark>
				<v-card-text>
					{{ loadingStep.text }}
					<v-progress-linear
						background-color="primary lighten-3"
						color="white"
						class="mb-0"
						:value="(loadingStep.step / numberOfSteps) * 100"
					></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'
import Validator from '@/mixins/Validator'
import FileTypeChecker from '@/mixins/FileTypeChecker'
import XlsxFileReader from '@/mixins/XlsxFileReader'
import * as Files from '@/helpers/files'

export default {
	name: 'CustomerImport',
	components: {
		DropZone: () => import('@/components/Commons/DropZone'),
		ErrorsList: () => import('@/components/SuperAdmin/Import/ErrorsList'),
		FileList: () => import('@/components/Commons/FileList')
	},
	mixins: [SuperAdminModuleGuard, Validator, XlsxFileReader, FileTypeChecker],
	data: function () {
		return {
			accountingFirms: new Array(),
			acceptedImportFileType: [Files.mimetypes.xlsx].join(','),
			selectedAccountingFirmId: null,
			customersToImport: [],
			doImportCustomers: true,
			doImportUsers: true,
			errorsReport: {
				customers: [],
				accountingFirmUsers: []
			},
			excelFile: undefined,
			loading: false,
			loadingAccountingFirmsList: false,
			loadingStep: {},
			showErrors: false,
			usersToImport: [],
			errors: {
				invalidDate: 'Invalid Date'
			}
		}
	},
	computed: {
		numberOfSteps: function () {
			let result = 0
			if (this.doImportCustomers) {
				result++
			}
			if (this.doImportUsers) {
				result++
			}
			return result
		},
		excelFileList: function () {
			return this.excelFile ? [this.excelFile] : []
		}
	},
	created: function () {
		this.getAccountingFirms()
	},
	methods: {
		createCustomers: function () {
			return this.service.createCustomers(this.selectedAccountingFirmId, this.excelFile).catch(err => {
				if (err && err.response && err.response.status === 422) {
					const errors = err.response.data.errors

					Object.keys(errors).forEach(key => {
						this.errorsReport.customers = this.errorsReport.customers.concat(errors[key])
					})

					this.showErrors = true
				} else {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, err)
					throw err
				}
			})
		},
		createUsers: function () {
			return this.service.createUsers(this.selectedAccountingFirmId, this.excelFile).catch(err => {
				if (err && err.response && err.response.status === 422) {
					const errors = err.response.data.errors

					Object.keys(errors).forEach(key => {
						this.errorsReport.accountingFirmUsers = this.errorsReport.accountingFirmUsers.concat(errors[key])
					})

					this.showErrors = true
				} else {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, err)
					throw err
				}
			})
		},
		getAccountingFirms: function () {
			this.loadingAccountingFirmsList = true
			this.service
				.getAccountingFirms()
				.then(accountingFirms => {
					return accountingFirms.map(accountingFirm => {
						return {
							text: accountingFirm.name,
							value: accountingFirm.id
						}
					})
				})
				.then(accountingFirms => {
					this.accountingFirms = accountingFirms
				})
				.finally(() => {
					this.loadingAccountingFirmsList = false
				})
		},
		importData: async function () {
			this.loading = true
			let i = 0
			this.errorsReport = {
				customers: [],
				accountingFirmUsers: []
			}
			Promise.resolve()
				.then(() => {
					if (this.doImportCustomers) {
						this.loadingStep = {
							step: i++,
							text: this.$t('sa.import.creating_customers')
						}
						return this.createCustomers()
					}
				})
				.then(() => {
					if (this.doImportUsers) {
						this.loadingStep = {
							step: i++,
							text: this.$t('sa.import.creating_users')
						}
						return this.createUsers()
					}
				})
				.then(() => {
					if (!this.showErrors) {
						this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.import.success'))
					}
				})
				.finally(() => {
					this.loading = false
				})
		},
		onInput: async function () {
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			this.errorsReport = {
				customers: [],
				accountingFirmUsers: []
			}
			if (!this.excelFile) {
				this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('sa.import.customers.bad_file'))
			} else {
				Object.defineProperties(this.excelFile, {
					type: {
						value: (await this.getFileType(this.excelFile)).mime,
						writable: true
					}
				})
				this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
			}
		}
	}
}
</script>
