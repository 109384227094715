import FileType from '@/plugins/fileType'

/**
 * Compute file type
 *
 * useful to check / prevent incorrect or missing type when importing
 */
export default {
	methods: {
		getFileType: async function (file) {
			return await FileType.fromBlob(file)
		}
	}
}
