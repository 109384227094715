import XLSX from 'xlsx'

const convertStringToInt = col => {
	let result = 0

	const reverseColName = col.split('').reverse().join('')

	for (let i = 0; i < reverseColName.length; i++) {
		const charValue = reverseColName.charCodeAt(i) - 64
		result += charValue * Math.pow(26, i)
	}
	return result - 1
}

const getCoordinates = cellName => {
	const parts = cellName.match(/^([A-Z]+)(\d+)$/)
	return {
		x: convertStringToInt(parts[1]),
		y: parseInt(parts[2]) - 1
	}
}

export default {
	methods: {
		findSheetWithName: function (xlsxData, sheetName) {
			const sheets = xlsxData.Sheets
			let sheet = sheets[sheetName]
			if (!sheet) {
				throw new Error(this.$t('sheet_not_found', { name: sheetName }))
			}
			return sheet
		},
		parseSheetToMatrix: function (sheet, correspondanceTable = null, ignoreFirstLine = false) {
			let result = []
			const keys = Object.keys(sheet)

			// TODO: refactor
			// eslint-disable-next-line sonarjs/cognitive-complexity
			keys.forEach(key => {
				if (/^[A-Z]+\d+$/.test(key)) {
					const coords = getCoordinates(key)
					if (!correspondanceTable == null) {
						if (result.length < coords.x + 1) {
							result.push([])
						}
						result[coords.x][coords.y] = sheet[key].w
					} else if (!ignoreFirstLine || (ignoreFirstLine && coords.y > 0)) {
						let y = coords.y
						if (ignoreFirstLine) {
							y = y - 1
						}
						if (result.length < y + 1) {
							result.push({})
						}
						const entry = correspondanceTable[coords.x]
						if (!entry) {
							return
						}
						let value = sheet[key].w
						let newKey = entry.name
						if (entry.type === 'boolean') {
							value = value === 'Oui'
						} else if (entry.type === 'date') {
							let newDate = new Date((sheet[key].v - 25569) * 86400 * 1000)
							if (newDate.toString() === 'Invalid Date') {
								value = 'Invalid Date'
							} else {
								value = newDate.toJSON().substring(0, 10)
							}
						} else if (entry.type === 'number') {
							value = parseInt(value)
						} else if (entry.type === 'string') {
							value = value.trim()
						}
						if (value || entry.required) {
							result[y][newKey] = value
						}
					}
				}
			})
			return result
		},
		readXlsxFile: function (xlsxFile) {
			return new Promise(res => {
				const allowedTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
				if (!xlsxFile || !xlsxFile.type || !allowedTypes.includes(xlsxFile.type)) {
					throw new TypeError('Only the file with mime type equals to "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" are accepted')
				}
				let fileReader = new FileReader()
				fileReader.onload = function (e) {
					let data = new Uint8Array(e.target.result)
					let workbook = XLSX.read(data, { type: 'array' })
					res(workbook)
				}
				fileReader.readAsArrayBuffer(xlsxFile)
			})
		}
	}
}
